const app = {
  init: function () {
    this.findAnchorLinks();
    this.showCookieMsg();

    // set current year in cporight text
    const copyrightYearSpan = document.getElementById("copyright-year");

    if (copyrightYearSpan != null) {
      copyrightYearSpan.innerText = new Date().getFullYear();
    }

    this.initVideos();
  },

  trace: function (io) {
    console.log(io);
  },

  findAnchorLinks: function () {
    // find all links where href starts with #
    let anchorLinks = Array.from(document.querySelectorAll('a[href*="#"]'));

    if (anchorLinks) {
      anchorLinks.map((link) => {
        link.addEventListener("click", function (e) {
          e.preventDefault();

          let currentTarget = this.getAttribute("href");
          let currentTargetY = document.querySelector(currentTarget).offsetTop;

          window.scrollTo({
            top: currentTargetY,
            behavior: "smooth",
          });
        });
      });
    }
  },

  showCookieMsg() {
    const cookieMsgEl = document.querySelector(".cookie-msg");
    const cookieMsgCloseBtn = document.querySelector(".cookie-msg__btn-close");

    if (cookieMsgCloseBtn !== null) {
      cookieMsgCloseBtn.addEventListener("click", () => {
        cookieMsgEl.classList.remove("cookie-msg--show");
      });
    }

    let hasShownCookieMsg = Cookies.get("mnd-cookie-msg");

    if (hasShownCookieMsg === undefined) {
      if (cookieMsgEl !== null) {
        cookieMsgEl.classList.add("cookie-msg--show");
        Cookies.set("mnd-cookie-msg", 1, { expires: 365 });
        hasShownCookieMsg = Cookies.get("mnd-cookie-msg");
      }
    }
  },

  initVideos() {
    fluidvids.init({
      selector: ["iframe", "object"], // runs querySelectorAll()
      players: ["www.youtube.com", "player.vimeo.com"], // players to support
    });

    const heroContainer = document.getElementById("hero-container");
    const heroVideo = document.getElementById("hero-video");

    if (heroVideo) {
      heroVideo.pause();
      heroVideo.play();

      heroVideo.addEventListener("suspend", () => {
        heroVideo.play();

        if (heroVideo.paused) {
          if (heroContainer) {
            heroContainer.classList.add("video-disabled");
          }
        }
      });
    }
  },
};
app.init();
